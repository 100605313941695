<template>
  <div class="ui teal segment">
    <h4>
      Liaison
      <button
        class="ui small compact right floated icon button remove-formset"
        type="button"
        @click="remove_linked_formset"
      >
        <i
          class="ui times icon"
          aria-hidden="true"
        />
      </button>
    </h4>
    <ul
      id="errorlist-links"
      class="errorlist"
    >
      <li
        v-for="error in form.errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
    <div class="visible-fields">
      <div class="two fields">
        <div class="required field">
          <label :for="form.relation_type.id_for_label">{{
            form.relation_type.label
          }}</label>
          <Dropdown
            :options="relationTypeChoices"
            :selected="selected_relation_type"
            :selection.sync="selected_relation_type"
          />
          {{ form.relation_type.errors }}
        </div>
        <div class="required field">
          <label :for="form.feature_to.id_for_label">{{
            form.feature_to.label
          }}</label>
          <SearchFeature
            :current-selection="linkedForm"
            @select="selectFeatureTo"
            @close="selectFeatureTo"
          />
          {{ form.feature_to.errors }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import SearchFeature from '@/components/SearchFeature.vue';

export default {
  name: 'FeatureLinkedForm',

  components: {
    Dropdown,
    SearchFeature
  },

  props: {
    linkedForm: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      form: {
        errors: null,
        relation_type: {
          errors: null,
          id_for_label: 'relation_type',
          html_name: 'relation_type',
          label: 'Type de liaison',
          value: {
            name: 'Doublon',
            value: 'doublon',
          },
        },
        feature_to: {
          errors: null,
          id_for_label: 'feature_to',
          field: {
            max_length: 30,
          },
          html_name: 'feature_to',
          label: 'Signalement lié',
          value: '',
        },
      },
      relationTypeChoices: [
        { name: 'Doublon', value: 'doublon' },
        { name: 'Remplace', value: 'remplace' },
        { name: 'Est remplacé par', value: 'est_remplace_par' },
        { name: 'Dépend de', value: 'depend_de' },
      ],
    };
  },

  computed: {
    selected_relation_type: {
      // getter
      get() {
        return this.form.relation_type.value.name;
      },
      // setter
      set(newValue) {
        this.form.relation_type.value = newValue;
        this.updateStore();
      },
    }
  },

  mounted() {
    if (this.linkedForm.relation_type) {
      this.form.relation_type.value.name = this.linkedForm.relation_type_display;
      this.form.relation_type.value.value = this.linkedForm.relation_type;
    }
    if (this.linkedForm.feature_to) {
      this.form.feature_to.value = this.linkedForm.feature_to.feature_id;
    }
  },

  methods: {
    remove_linked_formset() {
      this.$store.commit('feature/REMOVE_LINKED_FORM', this.linkedForm.dataKey);
    },

    selectFeatureTo(feature) {
      if (feature && feature.feature_id) {
        this.form.feature_to.value = feature.feature_id;
        this.updateStore();
      }
    },

    updateStore() {
      this.$store.commit('feature/UPDATE_LINKED_FORM', {
        dataKey: this.linkedForm.dataKey,
        relation_type: this.form.relation_type.value.value,
        feature_to: {
          feature_id: this.form.feature_to.value,
        },
      });
    },

    checkForm() {
      if (this.form.feature_to.value === '') {
        this.form.errors = [
          'Veuillez choisir un signalement pour la nouvelle liaison.',
        ];
        document
          .getElementById('errorlist-links')
          .scrollIntoView({ block: 'start', inline: 'nearest' });
        return false;
      }
      this.form.errors = [];
      return true;
    },
  },
};
</script>
